<template>
  <div class="box">
    <div class="search">

      <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="店铺名称：" prop="id">
              <el-input v-model="queryInfo.condition.businessName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="客户名称：" prop="businessName">
              <el-input v-model="queryInfo.condition.nikeName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商品名称：" prop="applicantTime">
              <el-input v-model="queryInfo.condition.productName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="订单号：" prop="linkPhone">
              <el-input v-model="queryInfo.condition.id" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="交易时间：" prop="industryType">
              <el-date-picker @change="change" v-model="valuechange" style="width: 100%;" type="daterange"
                start-placeholder="开始日期" end-placeholder="结束日期" />
            </el-form-item>
          </el-col>
          <el-col :span="8" style="display: flex; justify-content: flex-end">
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="支付方式：" prop="industryType">
              <el-select v-model="queryInfo.condition.payChanel" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
        </el-row>
        <!-- <el-row>
          <el-col :span="8">
            <el-form-item label="交易时间：" prop="industryType">
              <el-date-picker @change="change" v-model="valuechange" style="width: 100%;" type="daterange" start-placeholder="开始日期"
                end-placeholder="结束日期" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作员：" prop="businessStatus">
              <el-input v-model="queryInfo.condition.name" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8" style="display: flex; justify-content: flex-end">
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>

        </el-row> -->
      </el-form>
    </div>
    <div class="search-top">
      <div :class="i == tapshow ? 'active' : ''" v-for="(item, i) in toplist" :key="i" @click="tapchange(i)">{{
        item.name }}
      </div>
    </div>


    <div v-show="tapshow == 2">
      <el-table :data="tableData" style="width: 100%" :summary-method="summaryTotal" :show-summary="true" height="calc(100vh - 320px)" border :header-cell-style="{
        'background-color': '#f2f2f2',
        'text-align': 'center',
        border: '1px solid #ddd',
      }">
        <el-table-column prop="id" label="订单号" width="160" align="center">
        </el-table-column>
        <el-table-column prop="creatTime" label="下单时间" width="150" align="center">
        </el-table-column>
        <el-table-column prop="businessName" label="店铺名称" width="250" align="center">
        </el-table-column>
        <el-table-column prop="totalNum" label="订单总额" width="100" align="center">
        </el-table-column>
        <el-table-column prop="amountNum" label="实收金额" align="center">
        </el-table-column>
        <el-table-column prop="packingNum" label="打包费" align="center">
        </el-table-column>
          <el-table-column prop="freightNum" label="运费" align="center">
        </el-table-column>
        <el-table-column prop="platformNum" label="平台服务费" width="150" align="center">
        </el-table-column>
        <el-table-column prop="amountWechatNum" label="微信支付" align="center">
        </el-table-column>
        <el-table-column prop="" label="余额支付" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.totalNum-scope.row.amountWechatNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="integral" label="积分抵扣" align="center">
        </el-table-column>
        <el-table-column prop="userCouponsNum" label="优惠券抵扣" width="100" align="center">
        </el-table-column>
        <el-table-column prop="userOrderStatus" label="订单状态" align="center">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.userOrderStatus == 1 ? '购物车' :
              scope.row.userOrderStatus == 2 ? '待接单' :
                scope.row.userOrderStatus == 3 ? '进行中' :
                  scope.row.userOrderStatus == 4 ? '待核销' :
                    scope.row.userOrderStatus == 5 ? '已完成' :
                      scope.row.userOrderStatus == 6 ? '已评价' :
                        scope.row.userOrderStatus == 7 ? '售后' :
                          scope.row.userOrderStatus == 8 ? '已退款' :
                            scope.row.userOrderStatus == 9 ? '已取消' : '已删除' }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="nikeName" label="客户昵称" align="center">
        </el-table-column>
        <el-table-column prop="userPhone" label="客户手机号码" width="150" align="center">
        </el-table-column>

        <el-table-column prop="verificationUserName" label="核销操作员" width="150" align="center">
        </el-table-column>

        <!-- <el-table-column prop=" " label="操作" width="200" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" v-if="tapshow == 3" @click="sale(scope.row.id)">售后处理</el-button>

            <el-button type="text" @click="deta(scope.row.id)">详情</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>



    <!-- <div v-show="tapshow == 1">
  <el-table :data="tableData" style="width: 100%" height="calc(100vh - 320px)" border :header-cell-style="{
        'background-color': '#f2f2f2',
        'text-align': 'center',
        border: '1px solid #ddd',
      }">
    <el-table-column prop="businessId" label="订单号" width="160" align="center">
    </el-table-column>
    <el-table-column prop="businessName" width="150" label="店铺名称" align="center">
    </el-table-column>
    <el-table-column prop="totalNum" label="订单总额" width="100" align="center">
    </el-table-column>
    <el-table-column prop="amountNum" label="实收金额" align="center">
    </el-table-column>
    <el-table-column prop="freightNum" label="打包费" align="center">
    </el-table-column>
    <el-table-column prop="freightNum" label="运费" width="100" align="center">
    </el-table-column>
    <el-table-column prop="platformNum" label="平台服务费（10%）" width="150" align="center">
    </el-table-column>
    <el-table-column prop="amountNum" label="微信支付" align="center">
    </el-table-column>
    <el-table-column prop="amountNum" label="余额支付" align="center">
    </el-table-column>
    <el-table-column prop="integral" label="积分抵扣" align="center">
    </el-table-column>
    <el-table-column prop="userCouponsNum" width="150" label="优惠券抵扣" align="center">
    </el-table-column>
    <el-table-column prop="userOrderStatus" label="订单状态" align="center">
      <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.userOrderStatus == 1 ? '购物车' :
              scope.row.userOrderStatus == 2 ? '待接单' :
                scope.row.userOrderStatus == 3 ? '进行中' :
                  scope.row.userOrderStatus == 4 ? '待核销' :
                    scope.row.userOrderStatus == 5 ? '已完成' :
                      scope.row.userOrderStatus == 6 ? '已评价' :
                        scope.row.userOrderStatus == 7 ? '售后' :
                          scope.row.userOrderStatus == 8 ? '已退款' :
                            scope.row.userOrderStatus == 9 ? '已取消' : '已删除' }}</el-tag>
          </template>
    </el-table-column>
    <el-table-column prop="commodityType" label="订单类型" align="center">
      <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.commodityType == 1 ? '团购' :
              scope.row.commodityType == 2 ? '外卖' :
                scope.row.commodityType == 3 ? '积分商品' :
                  scope.row.commodityType == 4 ? '帮我买' : '帮我送' }}</el-tag>
          </template>
    </el-table-column>
    <el-table-column prop="nikeName" label="客户昵称" align="center">
    </el-table-column>
    <el-table-column prop="userPhone" label="客户手机号码" width="100" align="center">
    </el-table-column>
    <el-table-column prop="distributionUserName" label="骑手名称" align="center">
    </el-table-column>
    <el-table-column prop="distriPhone" label="骑手手机号" width="100" align="center">
    </el-table-column>
    <el-table-column prop="auditStatus" width="150" label="核销操作员账号" align="center">
    </el-table-column>
    <el-table-column prop="creatTime" label="下单时间" width="150" align="center">
    </el-table-column>
    <el-table-column prop=" " label="操作" width="200" fixed="right" align="center">
      <template slot-scope="scope">
            <el-button type="text" @click="deta(scope.row.id)">详情</el-button>
          </template>
    </el-table-column>
  </el-table>
  <div class="pagination">
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </div>



</div>



<div v-show="tapshow == 2">
  <el-table :data="tableData" style="width: 100%" height="calc(100vh - 320px)" border :header-cell-style="{
        'background-color': '#f2f2f2',
        'text-align': 'center',
        border: '1px solid #ddd',
      }">
    <el-table-column prop="businessId" label="订单号" width="160" align="center">
    </el-table-column>
    <el-table-column prop="businessName" label="店铺名称" align="center">
    </el-table-column>
    <el-table-column prop="totalNum" label="订单总额" width="100" align="center">
    </el-table-column>
    <el-table-column prop="amountNum" label="实收金额" align="center">
    </el-table-column>
    <el-table-column prop="freightNum" label="打包费" align="center">
    </el-table-column>
    <el-table-column prop="freightNum" label="运费" width="100" align="center">
    </el-table-column>
    <el-table-column prop="platformNum" label="平台服务费（10%）" width="150" align="center">
    </el-table-column>
    <el-table-column prop="amountNum" label="微信支付" align="center">
    </el-table-column>
    <el-table-column prop="amountNum" label="余额支付" align="center">
    </el-table-column>
    <el-table-column prop="integral" label="积分抵扣" align="center">
    </el-table-column>
    <el-table-column prop="userCouponsNum" label="优惠券抵扣" width="100" align="center">
    </el-table-column>
    <el-table-column prop="userOrderStatus" label="订单状态" align="center">
      <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.userOrderStatus == 1 ? '购物车' :
              scope.row.userOrderStatus == 2 ? '待接单' :
                scope.row.userOrderStatus == 3 ? '进行中' :
                  scope.row.userOrderStatus == 4 ? '待核销' :
                    scope.row.userOrderStatus == 5 ? '已完成' :
                      scope.row.userOrderStatus == 6 ? '已评价' :
                        scope.row.userOrderStatus == 7 ? '售后' :
                          scope.row.userOrderStatus == 8 ? '已退款' :
                            scope.row.userOrderStatus == 9 ? '已取消' : '已删除' }}</el-tag>
          </template>
    </el-table-column>
    <el-table-column prop="commodityType" label="订单类型" align="center">
      <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.commodityType == 1 ? '团购' :
              scope.row.commodityType == 2 ? '外卖' :
                scope.row.commodityType == 3 ? '积分商品' :
                  scope.row.commodityType == 4 ? '帮我买' : '帮我送' }}</el-tag>
          </template>
    </el-table-column>
    <el-table-column prop="nikeName" label="客户昵称" align="center">
    </el-table-column>
    <el-table-column prop="userPhone" label="客户手机号码" width="150" align="center">
    </el-table-column>
    <el-table-column prop="distributionUserName" label="骑手名称" align="center">
    </el-table-column>
    <el-table-column prop="distriPhone" label="骑手手机号" width="150" align="center">
    </el-table-column>
    <el-table-column prop="verificationUserCode" label="核销操作员账号" width="150" align="center">
    </el-table-column>
    <el-table-column prop="creatTime" label="下单时间" width="150" align="center">
    </el-table-column>
    <el-table-column prop=" " label="操作" width="200" fixed="right" align="center">
      <template slot-scope="scope">
            <el-button type="text" @click="deta(scope.row.id)">详情</el-button>
          </template>
    </el-table-column>
  </el-table>
  <div class="pagination">
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </div>
</div>
-->


    <div v-show="tapshow !== 2">
      <el-table :data="tableData" ref="table" :summary-method="summaryTotal" :show-summary="true"  style="width: 100%;padding-bottom: 30px;" height="calc(100vh - 320px)" border :header-cell-style="{
        'background-color': '#f2f2f2',
        'text-align': 'center',
        border: '1px solid #ddd',
      }">
        <el-table-column prop="id" label="订单号" width="160" align="center">

        </el-table-column>
                <el-table-column prop="creatTime" label="下单时间" width="150" align="center">
        </el-table-column>
        <el-table-column prop="businessName" label="店铺名称" width="250" align="center">
        </el-table-column>
        <el-table-column prop="totalNum" label="订单总额" width="100" align="center">
        </el-table-column>
        <el-table-column prop="paidNum" label="实收金额" align="center">

        </el-table-column>
        <el-table-column prop="packingNum" label="打包费" align="center">

        </el-table-column>
        <el-table-column prop="freightNum" label="运费" width="100" align="center">

        </el-table-column>
        <el-table-column prop="platformNum" label="平台服务费" width="150" align="center">

        </el-table-column>
        <el-table-column prop="amountWechatNum" label="微信支付" align="center">
        </el-table-column>
        <el-table-column prop="" label="余额支付" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.totalNum-scope.row.amountWechatNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="integral" label="积分抵扣" align="center">

        </el-table-column>
        <el-table-column prop="userCouponsNum" width="100" label="优惠券抵扣" align="center">

        </el-table-column>
        <el-table-column prop="userOrderStatus" label="订单状态" align="center">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.userOrderStatus == 1 ? '购物车' :
              scope.row.userOrderStatus == 2 ? '待接单' :
                scope.row.userOrderStatus == 3 ? '进行中' :
                  scope.row.userOrderStatus == 4 ? '待核销' :
                    scope.row.userOrderStatus == 5 ? '已完成' :
                      scope.row.userOrderStatus == 6 ? '已评价' :
                        scope.row.userOrderStatus == 7 ? '售后' :
                          scope.row.userOrderStatus == 8 ? '已退款' :
                            scope.row.userOrderStatus == 9 ? '已取消' : '已删除' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="commodityType" label="订单类型" align="center">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.commodityType == 1 ? '团购' :
              scope.row.commodityType == 2 ? '外卖' :
                scope.row.commodityType == 3 ? '积分商品' :
                  scope.row.commodityType == 4 ? '帮我买' : '帮我送' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="nikeName" label="客户昵称" align="center">

        </el-table-column>
        <el-table-column prop="userPhone" label="客户手机号码" width="150" align="center">

        </el-table-column>
        <el-table-column prop="distributionUserName" label="骑手名称" align="center">

        </el-table-column>
        <el-table-column prop="distriPhone" label="骑手手机号" width="150" align="center">

        </el-table-column>
        <el-table-column prop="verificationUserName" label="核销操作员" width="170" align="center">

        </el-table-column>


        <el-table-column prop="" label="操作" width="200" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" v-if="tapshow == 3&&scope.row.afterSalesStatus =='1'" @click="sale(scope.row.id,1)">售后处理</el-button>
            <el-button type="text" v-if="tapshow == 3&&scope.row.afterSalesStatus !=='1'" @click="deta(scope.row.id,2)">详情</el-button>
            <el-button type="text" v-if="tapshow !== 3" @click="deta(scope.row.id)">详情</el-button>
          </template>


        </el-table-column>

      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :visible.sync="showDialogtwo" width="710px" height="1000px">
      <div class="diatwo">
        <div class="order1">
          <div style="color: #1e98d7;">{{ detail.commodityType == 1 ? '团购' :
            detail.commodityType == 2 ? '外卖' :
              detail.commodityType == 3 ? '积分商品' :
                detail.commodityType == 4 ? '帮我买' : '帮我送'
            }}订单</div>
          <div>订单编号:{{ detail.id }}</div>
        </div>
        <div class="order2">
          <div class="li">当前订单状态：{{
            detail.userOrderStatus == 1 ? '购物车' :
              detail.userOrderStatus == 2 ? '待接单' :
                detail.userOrderStatus == 3 ? '进行中' :
                  detail.userOrderStatus == 4 ? '待核销' :
                    detail.userOrderStatus == 5 ? '已完成' :
                      detail.userOrderStatus == 6 ? '已完成' :
                        detail.userOrderStatus == 7 ? '已评价' : '售后'
          }}</div>


          <div class="li">订单类型：{{ detail.commodityType == 1 ? '团购' :
            detail.commodityType == 2 ? '外卖' :
              detail.commodityType == 3 ? '积分商品' :
                detail.commodityType == 4 ? '帮我买' : '帮我送'
            }}</div>
          <div class="li2">
            <div class="li-left">预</div>
            <div class="li-right">
              <div>配送方式 :{{ detail.deliveryMethod == 1 ? '商家自配送' : detail.deliveryMethod == 2 ? '平台骑手' :
                detail.deliveryMethod == 3 ? '闪送' : '待配送' }}</div>
              <div>预约配送时间：{{ detail.bookingTime }}</div>
            </div>
          </div>
        </div>
        <div class="order3">客户信息</div>
        <div class="order4">
          <div>客户昵称 :{{ detail.nikeName }}</div>
          <div>客户手机号: {{ detail.userPhone }}</div>
          <div>收货地址: {{ detail.areaName }}</div>
        </div>
        <div class="order3">订单信息</div>
        <div class="order4">
          <div>下单时间 :{{ detail.creatTime }}</div>
          <div>支付方式: {{ detail.payChanel == 1 ? '微信支付' : '钱包支付' }}</div>
          <div>订单备注: {{ detail.orderRemark }}</div>
          <div>打包费: {{ detail.packingNum }}元</div>
          <div>配送费: {{ detail.freightNum }}元</div>
          <div>平台服务费: {{ detail.platformNum }}元</div>
          <div>预计收入: {{ detail.amountNum }}元</div>
        </div>
        <div class="order5" v-if="tapshow == 0">
          <el-table :data="detail.commodityList" style="width: 100%" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
            <el-table-column prop="commodityName" label="套餐名称" width="160" align="center">
            </el-table-column>
            <el-table-column prop="logoImg" label="图片" width="160" align="center">
              <template slot-scope="scope">
                <img :src="scope.row.logoImg" width="50" height="50" alt="">
              </template>
            </el-table-column>
            <el-table-column prop="buyPrice" label="价格" width="160" align="center">
            </el-table-column>
            <el-table-column prop="commodityNum" label="数量" width="160" align="center">
            </el-table-column>
            <el-table-column prop="creatTime" label="开始时间" width="160" align="center">
            </el-table-column>
            <el-table-column prop="buyEndTime" label="结束时间" width="160" align="center">
            </el-table-column>

          </el-table>
        </div>
        <div class="order5" v-if="tapshow == 1">
          <el-table :data="detail.orderDetailList" style="width: 100%" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
            <el-table-column prop="commodityName" label="商品名称" width="160" align="center">
              <template slot-scope="scope">

              </template>
            </el-table-column>
            <el-table-column prop="bigImg" label="商品图片" width="160" align="center">
              <template slot-scope="scope">
                <img :src="scope.row.bigImg" width="50" height="50" alt="">
              </template>
            </el-table-column>
            <el-table-column prop="commodityName" label="商品" width="160" align="center">
            </el-table-column>
            <el-table-column prop="realPrice" label="价格" width="160" align="center">
            </el-table-column>
            <el-table-column prop="number" label="数量" width="160" align="center">
            </el-table-column>

          </el-table>
        </div>
        <div style="text-align: right;color: #1e98d7;">实收款: {{ detail.paidNum }}元</div>


      </div>
    </el-dialog>
    <el-dialog title="处理详情" :visible.sync="showDialogsale" width="800px" height="1000px">
      <div class="diasale">
        <div class="sale1">
          <div>订单编号: {{ detail.id }}</div>
          <div>付款时间: {{ detail.creatTime }}</div>
        </div>
        <div class="sale2">
          <div class="de1">支付方式: {{ detail.payChanel == 1 ? '微信支付' : '钱包' }} {{ detail.amountNum }}</div>
          <div class="de2">
            <div class="de-left" v-for="(item, i) in detail.orderDetailList" :key="i">
              <img :src="item.bigImg" alt="">
              <div class="de-jj">
                <div>{{ item.commodityName }}✖ {{ item.number }}</div>
                <div>￥{{ item.packingPrice }}/斤</div>
              </div>
            </div>
            <div class="de-right">订单实付金额：{{ detail.amountNum }}</div>
          </div>
          <div class="de3">
            <div class="de-t">退款原因: {{ detail.afterSalesContent }}</div>
            <div class="de-img">
              <img :src="detail.afterSalesFile" alt="">

            </div>
          </div>
        </div>
        <div class="sale3" v-if="detail.afterSalesStatus == 1">
          <div class="check">
            <el-radio v-model="afterSalesType" label="1">全部退款</el-radio>
            <el-radio v-model="afterSalesType" label="2">部分退款</el-radio>
          </div>
          <div class="input" style="display: flex;">
            <el-input type="text" v-model="afterSalesNum"></el-input>
            <div>元</div>
          </div>
        </div>
        <div class="sale4" v-if="detail.afterSalesStatus == 1">
          <div style="font-size: 14px;width: 100px;">审核备注:</div>
          <el-input type="textarea" v-model="afterSalesDealMessage" name="" id=""></el-input>

        </div>
        <div v-if="detail.afterSalesStatus == 2">
          <div class="replyBack"
            style="display: flex;align-items: center;justify-content: space-around;color: #D56700;margin: 20px 0;">
            <div style="text-align: left;width: 33.3%;">
              <span>处理结果：</span>
              <span>{{ detail.afterSalesType == 1 ? '全部退款' : '部分退款' }}</span>
            </div>
            <div style="text-align: left;width: 33.3%;">
              <span>退款金额：</span>
              <span>{{ detail.afterSalesNum }}</span>
            </div>
            <div style="text-align: left;width: 33.3%;">
              <span>退款时间：</span>
              <span>{{ detail.afterSalesDealTime }}</span>
            </div>
          </div>
          <div class="replayBackContent">
            <span>审核备注：</span>
            <span>{{ detail.afterSalesDealMessage }}</span>
          </div>
        </div>
        <div v-if="detail.afterSalesStatus == 3">
          <div class="#f40">售后未通过</div>
        </div>
        <div class="sale5" style="display: flex;justify-content: center;margin-top: 50px;">
          <el-button @click="showDialogsale=false">取消</el-button>
          <el-button type="primary" @click="submitForm('form')">确认</el-button>

        </div>

      </div>
    </el-dialog>
  </div>

</template>

<script>
export default {
  data() {
    return {
      showDialogtwo: false,
      showDialogsale: false,
      toplist: [{
        name: '团购订单',
        value: ''
      }, {
        name: '外卖订单',
        value: ''
      }, {
        name: '到店扫码支付订单',
        value: ''
      }, {
        name: '售后订单',
        value: ''
      }],
      tableData: [],
      total: 0,
      queryInfo: {
        pageSize: 30,
        pageCount: 1,
        currPage: 1,
        condition: {
          totalOrderType: 2,
          userOrderKey:1
        }
      },
      valuechange: '',
      form: {},
      tapshow: 0,
      options: [{
        value: '1',
        label: '店长'
      }, {
        value: '2',
        label: '员工'
      }],
      afterSalesType: '1', //是否全部退款
      afterSalesDealMessage: '',//审核内容
      afterSalesNum: "",//退款金额
      detail: {},
      i:'',
      amountWechatNum:'',
      freightNum:'',
      packingNum:'',
      paidNum:'',
      platformNum:'',
      totalNum:''

    };
  },
  created() {
    this.getList()
    this.getprice()


  },

updated() {
    this.$nextTick(() => {
        this.$refs.table.doLayout()
    })
},
  mounted() {

  },
  methods: {
    getprice() {
      var that = this;
      that.$http.post("/order/adminQueryOrderNum", that.queryInfo.condition).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.amountWechatNum = response.data.data.amountWechatNum
          that.freightNum = response.data.data.freightNum
          that.packingNum = response.data.data.packingNum
          that.paidNum = response.data.data.paidNum
          that.platformNum = response.data.data.platformNum
          that.totalNum = response.data.data.totalNum
          that.amountBalanceNum = response.data.data.amountBalanceNum
          that.amountCouponNum = response.data.data.amountCouponNum
          that.amountCashNum = response.data.data.amountCashNum


        }
      });

    },
        //自定义表格合计行
    summaryTotal(param) {
      let sums = []
      sums[0] = <div>总合计</div>

      sums[3] = <div>{this.totalNum}</div>
      sums[4] = <div>{this.paidNum}</div>
      sums[5] = <div>{this.packingNum}</div>
      sums[6] = <div>{this.freightNum}</div>
      sums[7] = <div>{this.platformNum}</div>
      sums[8] = <div>{this.amountWechatNum}</div>
      sums[9] = <div>{this.amountBalanceNum}</div>
      sums[10] = <div>{this.amountCouponNum}</div>
      sums[11] = <div>{this.amountCashNum}</div>
      return sums
    },
    change() {
      console.log(this.valuechange);
      this.queryInfo.condition.startTime = this.newDate(this.valuechange[0])
      this.queryInfo.condition.endTime = this.newDate(this.valuechange[1])
    },
    newDate(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    //获取数据
    getList() {
      var that = this;
      that.$http.post("/order/adminQueryOrder", that.queryInfo).then(function (response) {
        console.log(response.data.data, 'response.data.data');
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
    search() {
      // this.queryInfo.condition = this.form
      this.getList()
      this.getprice()
    },
    reset() {
      // this.form = {}
      this.queryInfo.condition = {}
      this.valuechange=''
      this.queryInfo.condition.totalOrderType = this.tapshow + 1
      this.queryInfo.condition.userOrderKey = 1
      this.getList()
    },
    tapchange(i) {
      this.tapshow = i
      if (i == 0) {
        this.queryInfo.condition.totalOrderType = 2
        this.getList()
        this.getprice()
      }
      if (i == 1) {
        this.queryInfo.condition.totalOrderType = 1
        this.getList()
        this.getprice()
      }
      if (i == 2) {
        this.queryInfo.condition.totalOrderType = 3
        this.getList()
        this.getprice()
      }
      if (i == 3) {
        this.queryInfo.condition.totalOrderType = 4
        this.getList()
        this.getprice()
      }

    },
    deta(id,i) {

      var that = this;
      that.i = i

      that.$http.post("/order/adminQueryOrderDetail", {
        id: id
      }).then(function (response) {
        console.log(response.data.data, 'response.data.data');
        if (response.data.code == 200) {
          if (response.data.data.commodityType == 1) {
            response.data.data.commodityList = [{
              commodityName: response.data.data.commodityName,
              logoImg: response.data.data.logoImg,
              buyPrice: response.data.data.buyPrice,
              useTime: response.data.data.useTime,
              commodityNum: 1,
              buyEndTime: response.data.data.buyEndTime,
              creatTime: response.data.data.creatTime,
            }]
            console.log("_+_+_+_+", response.data.data.commodityList);

          }
          that.detail = response.data.data
          if (that.tapshow !== 3) {
            that.showDialogtwo = true
          } else {
            that.showDialogsale = true
          }
        }
      });

    },
    sale(id,i) {
      var that = this;
      that.i = i
      that.$http.post("/order/adminQueryOrderDetail", {
        id: id
      }).then(function (response) {
        console.log(response.data.data, 'response.data.data');
        if (response.data.code == 200) {
          that.detail = response.data.data
          that.showDialogsale = true
        }
      });


    },
    submitForm() {
      var that = this;
      if(that.i == 1){
        that.$http.post("/order/merchantHandleAfterSale", {
        orderType: that.detail.orderType,
        id: that.detail.id,
        businessId: that.detail.businessId,
        afterSalesDealMessage: that.afterSalesDealMessage,
        afterSalesType: that.afterSalesType,
        afterSalesNum: that.afterSalesNum

      }).then(function (response) {

        if (response.data.code == 200) {
          that.$message.success('success');
          that.showDialogsale = false
        } else {
          that.$message.error(response.data.message);
        }
      });
      } else {
        that.showDialogsale = false
      }
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 10px;
    padding-bottom: 0;


  }

  .search-top {
    height: 33px;
    padding: 2px 2px 2px 2px;
    background-color: rgba(30, 152, 215, 0.14);
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    div {
      width: 150px;
      text-align: center;
      height: 33px;
      line-height: 33px;
    }

    .active {
      border-bottom: 2px solid #2097f6;
      color: #2097f6;
    }
  }

  .tab {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px
  }

  .dia {
    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 130px;
    }
  }

  .drawer {
    height: 900px;
    overflow-y: scroll;

    .drauser {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;

      div {
        width: 30%;
        color: #847575;
        margin-bottom: 30px;

        span {
          color: #000;
        }
      }
    }

    .draimg {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;

      .imgbox {
        width: 30%;
        display: flex;

        .imgname {
          color: #000;
        }

        .img {
          margin-top: 40px;
          display: flex;

          img {
            width: 89px;
            height: 65px;
          }
        }

      }
    }

    .bl {
      padding: 0 30px;

      .bl-li {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .bls {
          display: flex;
          align-items: center;
          margin-left: 15px;

          input {
            width: 100px;
            height: 32px;
            text-align: center
          }

          .text-box {
            width: 38px;
            height: 38px;
            background-color: rgba(230, 230, 230, 0.55);
            text-align: center;
            line-height: 38px;
          }
        }
      }
    }

    .qx {
      padding: 0 30px;
      margin-top: 30px;
    }
  }

  .diatwo {
    box-sizing: border-box;
    padding: 0 30px;

    .order1 {
      display: flex;
      margin-bottom: 10px;

      div {
        margin-right: 20px;
      }
    }

    .order2 {
      display: flex;

      .li {
        width: 206px;
        height: 80px;
        padding: 2px 2px 2px 2px;
        border: 1px solid #1e98d7;
        background-color: rgba(30, 152, 215, 0.06);
        box-sizing: border-box;
        color: #1e98d7;
        text-align: center;
        padding-top: 30px;

        margin-right: 20px;
      }

      .li2 {
        width: 286px;
        height: 80px;
        padding: 2px 2px 2px 2px;
        border: 1px solid #1e98d7;
        background-color: rgba(30, 152, 215, 0.06);
        box-sizing: border-box;
        color: #1e98d7;
        align-items: center;


        margin-right: 20px;
        display: flex;

        .li-left {
          color: rgba(251, 142, 0, 0.99);
          margin-left: 10px;
          margin-right: 10px;
        }

        .li-right {
          padding-top: 15px;
        }
      }
    }

    .order3 {
      font-weight: 700;
      color: #333333;
      margin-top: 30px;
    }

    .order4 {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0px;

      div {
        width: 50%;
        margin-top: 10px;
      }
    }

  }

  .diasale {
    color: #333333;
    font-size: 16px;

    .sale1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    .sale2 {
      margin-top: 5px;

      .de2 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .de-left {
          display: flex;

          img {
            width: 66px;
            height: 68px;
            border-radius: 5px;
          }

          .de-jj {
            margin-left: 5px;

            div {
              height: 33px;
              line-height: 33px;
            }

          }
        }

        .de-right {
          color: #e30606;
        }
      }

      .de3 {
        margin-top: 10px;

        .de-img {
          display: flex;

          img {
            width: 66px;
            height: 68px;
            border-radius: 5px;
            margin-right: 10px;
          }
        }

        border-bottom: 1px solid #e30606;
        padding-bottom: 40px
      }
    }

    .sale3 {
      display: flex;
      justify-content: space-between;
      margin-top: 20px
    }

    .sale4 {
      margin-top: 20px;
      display: flex;

      textarea {
        width: 680px;
        height: 60px;
        padding: 2px 2px 2px 2px;
        border-radius: 5px;
        border: 1px solid rgba(121, 121, 121, 0.26);
      }
    }
  }
}
</style>
